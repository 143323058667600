import * as React from 'react'
import { PrismicRichText } from '@prismicio/react'

export const PageHeader = ({
  title,
  subtitle,
  text,
  bgImage,
  bgColor,
  darkText,
}) => {
  let isBgColor = '#f2ede'

  switch (bgColor) {
    case 'Light':
      isBgColor = '#f2ede'
      break
    case 'Yellow':
      isBgColor = '#f7aa34'
      break
    case 'Blue':
      isBgColor = '#123d55'
      break
  }

  return (
    <section
      className="page-header"
      style={{
        backgroundColor: isBgColor,
        backgroundImage: `${
          bgImage &&
          `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url("${bgImage}")`
        }`,
      }}
    >
      <div className={`page-header-content ${darkText || `page-header-light`}`}>
        <h4>{subtitle}</h4>
        <h1>{title}</h1>
        <PrismicRichText field={text} />
      </div>
    </section>
  )
}
