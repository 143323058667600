import React from 'react'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import { PageHeader } from '../components/PageHeader'

import { components } from '../slices'

const PageTemplate = ({ data }) => {
  if (!data) return null
  const doc = data.prismicPage.data
  const headerEnabled = doc.enable_header

  return (
    <Layout darkMenu={doc.dark_menu}>
      <Seo title={doc.document_display_name.text} />
      {headerEnabled && (
        <PageHeader
          title={doc.header_title.text}
          subtitle={doc.header_subtitle.text}
          text={doc.header_text.richText}
          bgImage={doc.header_bg_image.url}
          bgColor={doc.header_bg_color}
          darkText={doc.dark_text}
        />
      )}

      <div className="slice-container">
        <SliceZone slices={doc.body} components={components} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($id: String) {
    prismicPage(id: { eq: $id }) {
      _previewable
      data {
        header_title {
          text
        }
        header_text {
          richText
        }
        header_subtitle {
          text
        }
        header_bg_image {
          url
        }
        header_bg_color
        enable_header
        document_display_name {
          text
        }
        dark_menu
        dark_text
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...PageDataBodyText
          ...PageDataBodyQuote
          ...PageDataBodyFullWidthImage
          ...PageDataBodyImageGallery
          ...PageDataBodyImageHighlight
          ...PageDataBodyPageBanner
          ...PageDataBodyCardsCarousel
          ...PageDataBodyCardsCarousel1
          ...PageDataBodySectionTitle
          ...PageDataBodyTwoCol
          ...PageDataBodyBlankSpace
          ...PageDataBodyCasesBlurbFullwidth
          ...PageDataBodyCasesBlurbThreecol
          ...PageDataBodyDownloads
        }
      }
    }
  }
`

export default withPrismicPreview(PageTemplate)
